import React, { useState, useEffect } from 'react';
import axios from 'axios';
// import Icon
import Icon from '../..//assets/img/icon/right.png'
import UserData from '../UserData/UserData';

const BookingConfirmationArea = (props) => {
    // Access user data from props location state
    // const userData = props.location.state.userData;
    const [userData, setUserData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('http://localhost:5000/api/travelark/user');
        setUserData(response.data);
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchData();
  }, []);

    UserData();
  return (
    <>
     <section id="tour_booking_submission" className="section_padding">
        <div className="container">
            <div className="row">
                <div className="col-lg-8">
                    <div className="tou_booking_form_Wrapper">
                        <div className="tour_booking_form_box mb-4">
                            <div className="booking_success_arae">
                                <div className="booking_success_img">
                                    <img src={Icon} alt="img" />
                                </div>
                                <div className="booking_success_text">
                                    <h3>{userData.first_name} {userData.last_name}, your order was submitted successfully!</h3>
                                    <h6>Your booking details has been sent to: {userData.email}</h6>
                                </div>
                            </div>
                        </div>
                        <div className="booking_tour_form">
                            <h3 className="heading_theme">Your information</h3>
                            <div className="tour_booking_form_box">
                                <div className="your_info_arae">
                                    <ul>
                                        <li><span className="name_first">First name:</span> <span className="last_name">{userData.first_name}</span></li>
                                        <li><span className="name_first">Last name:</span> <span className="last_name">{userData.last_name}</span></li>
                                        <li><span className="name_first">Email address:</span> <span className="last_name">{userData.email}</span></li>
                                        <li><span className="name_first">Phone Number:</span> <span className="phone">{userData.phone}</span></li>
                                        <li><span className="name_first">Address:</span> <span className="last_name">{userData.address}</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="tour_details_right_sidebar_wrapper">
                        <div className="tour_detail_right_sidebar">
                            <div className="tour_details_right_boxed">
                                <div className="tour_details_right_box_heading">
                                    <h3>Booking details</h3>
                                </div>
                                <div className="tour_booking_amount_area">
                                    <ul>
                                        <li>Booking ID: <span>#TARK100524</span></li>
                                        <li>Booking date: <span>10 May 2024</span></li>
                                        <li>Payment method: <span>Card Payment</span></li>
                                        <li>Booking status: <span>Success</span></li>
                                    </ul>
                                    <ul>
                                        <li>Adult Price x 1 <span>£3,300.00</span></li>
                                        <li className="remove_coupon_tour">Discount <span>10%</span></li>
                                        <li>Tax (incl) <span>5%</span></li>
                                    </ul>
                                    <div className="coupon_add_area">
                                        <h6><span className="remove_coupon_tour">Remove</span> TRAVELARKCODE(10% OFF)
                                            <span>£300.00</span>
                                        </h6>
                                    </div>
                                    {/* <div className="tour_bokking_subtotal_area">
                                        <h6 className="remove_coupon_tour">Subtotal <span>£3,000.00</span></h6>
                                    </div> */}
                                    <div className="total_subtotal_booking">
                                        <h6 className="remove_coupon_tour">Total Amount <span>£3,000.00</span> </h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}

export default BookingConfirmationArea