export const FooterData = [

    {
        heading:"Company",
        offset:true,
        item:[
         {
             FItem:"About Us",
             linkL:"/about"
         },
        //  {
        //      FItem:"Testimonials",
        //      linkL:"/"
        //  },
        {
             FItem:"Privacy Policy",
             linkL:"/"
        },
        //  {
        //      FItem:"Rewards",
        //      linkL:"/faqs"
        //  },
        //  {
        //      FItem:"Work with Us",
        //      linkL:"/about"
        //  },
        //  {
        //      FItem:"Meet the Team ",
        //      linkL:"/tour-guids"
        //  },
        //  {
        //      FItem:"Blog",
        //      linkL:"/news"
        //  },

        ]
     },
    //  {
    //     heading:"Support",
    //     offset:false,
    //     item:[
    //      {
    //          FItem:"Account",
    //          linkL:"/about"
    //      },
    //      {
    //          FItem:"Faq",
    //          linkL:"/faqs"
    //      },
    //      {
    //          FItem:"Legal",
    //          linkL:"/testimonials"
    //      },
    //      {
    //          FItem:"Contact",
    //          linkL:"/contact"
    //      },
    //      {
    //          FItem:"Affiliate Program",
    //          linkL:"/top-destinations"
    //      },
    //      {
    //          FItem:"Privacy Policy",
    //          linkL:"/privacy-policy"
    //      },

    //     ]
    //  },
    //  {
    //     heading:"Other Services",
    //     offset:false,
    //     item:[
    //      {
    //          FItem:"Community program",
    //          linkL:"/about"
    //      },
    //      {
    //          FItem:"Investor Relations",
    //          linkL:"/faqs"
    //      },
    //      {
    //          FItem:"Rewards Program",
    //          linkL:"/testimonials"
    //      },
    //      {
    //          FItem:"PointsPLUS",
    //          linkL:"/contact"
    //      },
    //      {
    //          FItem:"Partners",
    //          linkL:"/top-destinations"
    //      },
    //      {
    //          FItem:"List My Hotel",
    //          linkL:"/privacy-policy"
    //      },

    //     ]
    //  },
    //  {
    //     heading:"Top cities",
    //     offset:false,
    //     item:[
    //      {
    //          FItem:"Chicago",
    //          linkL:"/about"
    //      },
    //      {
    //          FItem:"New York",
    //          linkL:"/faqs"
    //      },
    //      {
    //          FItem:"San Francisco",
    //          linkL:"/testimonials"
    //      },
    //      {
    //          FItem:"California",
    //          linkL:"/contact"
    //      },
    //      {
    //          FItem:"Ohio",
    //          linkL:"/top-destinations"
    //      },
    //      {
    //          FItem:"Alaska",
    //          linkL:"/privacy-policy"
    //      },

    //     ]
    //  },

]