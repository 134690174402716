import React from "react";

const ContactArea = () => {
  return (
    <>
      <section id="contact_main_arae" className="section_padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="section_heading_center">
                <h2>Contact with us</h2>
              </div>
            </div>
          </div>
          <div className="contact_main_form_area_two">
            <div className="row">
              <div className="col-lg-8">
                <div className="contact_left_top_heading">
                  <h2>
                    Do you have any query? Contact with us to get any any
                    support.
                  </h2>
                  <h3>Leave us a message</h3>
                  <p>We welcome your messages and feedback. Please use the form below to get in touch with us. We'll respond promptly. Thank you for reaching out!</p>
                </div>
                <div className="contact_form_two">
                  <form action="!#" id="contact_form_content">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control bg_input"
                            placeholder="First name*"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control bg_input"
                            placeholder="Last name*"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control bg_input"
                            placeholder="Email address (Optional)"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control bg_input"
                            placeholder="Mobile number*"
                          />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group">
                          <textarea
                            className="form-control bg_input"
                            rows="5"
                            placeholder="Message"
                          ></textarea>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group">
                          <button type="button" className="btn btn_theme btn_md">
                            Send message
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="contact_two_left_wrapper">
                  <h3>Contact details</h3>
                  <div className="contact_details_wrapper">
                    <div className="contact_detais_item">
                      <h4>Help line</h4>
                      <h3>
                        <a href="tel:+01-234-567-890">++44 020 80586216</a>
                      </h3>
                    </div>
                    <div className="contact_detais_item">
                      <h4>Support mail</h4>
                      <h3>
                        <a href="mailto:info@travelark.co.uk">
                          info@travelark.co.uk
                        </a>
                      </h3>
                    </div>
                    <div className="contact_detais_item">
                      <h4>Contact hour</h4>
                      <h3>Mon-Fri : 08:00 - 17:00</h3>
                    </div>
                    <div className="contact_map_area">
                      <iframe
                        title="Google Map"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2482.2356912754544!2d-0.09124032347967953!3d51.52723677181779!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48761d5b8c1bac8b%3A0x94361cc71a032ed3!2s128%20City%20Road%2C%20London%2C%20United%20Kingdom%2C%20EC1V%202NX!5e0!3m2!1sen!2suk!4v1712970291201!5m2!1sen!2suk"
                      ></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactArea;
