import React, { useState } from "react";
import { Link } from "react-router-dom";
import api from "../api";

const LoginArea = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [userData, setUserData] = useState(null);
  const [loggedIn, setLoggedIn] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [newPassword, setNewPassword] = useState(""); // Define newPassword state
  const [confirmNewPassword, setConfirmNewPassword] = useState(""); // Define confirmNewPassword state

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await api.post("/login", { email, password });
      const { token, ...userInfo } = response.data;
      localStorage.setItem("token", token); // Store JWT token in localStorage
      setUserData(userInfo); // Store user data in state
      setLoggedIn(true); // Set logged in state to true
      console.log("Login successful:", response.data);
      if (token) {
        // Create a new Date object representing the current date and time
        const currentDate = new Date();

        // Add 1 hour to the current date
        const expiryDate = new Date();
        expiryDate.setTime(currentDate.getTime() + 1 * 60 * 60 * 1000);

        console.log("Current Date:", currentDate); // Log the current date
        console.log("Expiry Date:", expiryDate); // Log the expiry date

        document.cookie = `token=${token}; expires=${expiryDate.toUTCString()}; path=/`;

        // Redirect the user to the home page or perform any other actions
      }
    } catch (error) {
      if (error.response) {
        setError(error.response.data.message);
      } else {
        setError("An error occurred. Please try again.");
      }
    }
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = async () => {
    try {
      let requestBody = {
        first_name: userData.first_name,
        last_name: userData.last_name,
        email: userData.email,
        phone: userData.phone,
        address: userData.address,
      };

      // Check if newPassword and confirmNewPassword are not empty
      if (newPassword && confirmNewPassword) {
        // Add password fields to the request body
        requestBody = {
          ...requestBody,
          isPwd: true,
          currentPassword: password,
          newPassword: newPassword,
          confirmNewPassword: confirmNewPassword,
        };
      }

      const response = await api.patch(`/user/${userData.id}`, requestBody);
      console.log("User data updated successfully:", response.data);
      setIsEditing(false);
    } catch (error) {
      if (error.response) {
        setError(error.response.data.message);
      } else {
        setError("An error occurred. Please try again.");
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };

  const handleBackClick = () => {
    // Implement back functionality here
    setIsEditing(false);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleLogout = () => {
    // Clear token and user data from localStorage
    localStorage.removeItem("token");
    localStorage.removeItem("userData");
    setLoggedIn(false);
    setUserData(null);
  };

  // Function to check if user is authenticated
  const isAuthenticated = () => {
    return !!localStorage.getItem("token");
  };

  // Render login form if not logged in
  if (!loggedIn) {
    return (
      <>
        <section id="common_author_area" className="section_padding">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 offset-lg-2">
                <div className="common_author_boxed">
                  <div className="common_author_heading">
                    <h3>Login your account</h3>
                  </div>
                  <div className="common_author_form">
                    <form onSubmit={handleSubmit}>
                      <div className="form-group">
                        <input
                          type="email"
                          name="email"
                          className="form-control"
                          placeholder="Your email address*"
                          required
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="password"
                          name="password"
                          className="form-control"
                          placeholder="Password*"
                          required
                          onChange={(e) => setPassword(e.target.value)}
                        />
                        <br></br>
                        <Link to="/forget-password">Forgot password?</Link>
                      </div>
                      {error && <div className="error-message">{error}</div>}
                      <div className="common_form_submit">
                        <button type="submit" className="btn btn_theme btn_md">
                          Log in
                        </button>
                      </div>
                      <div className="have_acount_area">
                        <span>Don't have an account? </span>
                        <Link to="/register">Register now</Link>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }

  // Render user data if logged in
  return (
    <>
      <section>
        <div className="profile-container">
          <h2>User Profile</h2>
          <br />
          <div className="profile-details">
            <div className="profile-field">
              <label>Full Name:</label>
              {isEditing ? (
                <input
                  type="text"
                  name="fullName"
                  value={userData.first_name + " " + userData.last_name}
                  onChange={handleChange}
                />
              ) : (
                <span>{userData.first_name + " " + userData.last_name}</span>
              )}
            </div>
            <div className="profile-field">
              <label>Email:</label>
              <span>{userData.email}</span>
            </div>
            <div className="profile-field">
              <label>Password:</label>
              {isEditing ? (
                <input
                  type="password"
                  name="password"
                  value={password}
                  onChange={handlePasswordChange}
                />
              ) : (
                <span>********</span>
              )}
            </div>
            <div className="profile-field">
              <label>Phone Number:</label>
              {isEditing ? (
                <input
                  type="text"
                  name="phone"
                  value={userData.phone}
                  onChange={handleChange}
                />
              ) : (
                <span>{userData.phone}</span>
              )}
            </div>
            <div className="profile-field">
              <label>Address:</label>
              {isEditing ? (
                <input
                  type="text"
                  name="address"
                  value={userData.address}
                  onChange={handleChange}
                />
              ) : (
                <span>{userData.address}</span>
              )}
            </div>
          </div>
          <div className="profile-actions">
            {isEditing ? (
              <>
                <div className="btn btn_navber" onClick={handleSaveClick}>
                  <p>Save</p>
                </div>
                <div className="btn btn_navber" onClick={handleBackClick}>
                  <p>Back</p>
                </div>
              </>
            ) : (
              <div className="btn btn_navber" onClick={handleEditClick}>
                <p>Edit Profile</p>
              </div>
            )}
            <div className="btn btn_navber" onClick={handleLogout}>
              <p>Logout</p>
            </div>
          </div>
          <br />
          <br />
          <div className="option-item">
            <Link
              to={{
                pathname: "/booking-confirmation",
                state: { userData: userData },
              }}
            >My Bookings
            </Link>
          </div>
        </div>
      </section>
    </>
  );
};

// export { handleLogout };

export default LoginArea;
