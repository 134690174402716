// Item Img
import img1 from '../../../assets/img/home/hotel/hotel1.png'
import img2 from '../../../assets/img/home/hotel/hotel2.png'
import img3 from '../../../assets/img/home/hotel/hotel3.png'
import img4 from '../../../assets/img/home/hotel/hotel4.png'
import img5 from '../../../assets/img/home/hotel/hotel5.png'
import img6 from '../../../assets/img/home/hotel/hotel6.png'
import img7 from '../../../assets/img/home/hotel/hotel7.png'
import img8 from '../../../assets/img/home/hotel/hotel8.png'
//Item Details Big Img
import BigImg from '../../../assets/img/home/home-hoilday.jpg'



export const ExploreData = [
    {
        id:1,
        img: img1,
        bigImg:BigImg,
        location:"New beach, Thailand",
        heading: "Kantua hotel, Thailand",
        reviewRating:"4.8/5 Excellent",
        reviewCount:"(1214 reviewes)",
        price:"£99.00",  
        discount:true,
        discountPrice:"50%"
    },
    {
        id:2,
        img: img2,
        location:"Indonesia",
        heading: "Hotel paradise international",
        reviewRating:"4.8/5 Excellent",
        reviewCount:"(1214 reviewes)",
        price:"£69.00",
        discount:false,
    },
    {
        id:3,
        img: img3,
        location:"Kualalampur",
        heading:"Hotel kualalampur",
        reviewRating:"4.8/5 Excellent",
        reviewCount:"(1214 reviewes)",
        price:"£79.00",
        discount:false,
    },
    {
        id:4,
        img: img4,
        location:"Mariana island",
        heading: "Hotel deluxe",
        reviewRating:"4.8/5 Excellent",
        reviewCount:"(1214 reviewes)",
        price:"£99.00",
        discount:true,
        discountPrice:"20%"
    },
    {
        id:5,
        img: img5,
        location:"imgKathmundu, Nepal",
        heading: "Hotel rajavumi",
        reviewRating:"4.1/5 Excellent",
        reviewCount:"(114 reviewes)",
        price:"£49.00",
        discount:false,
    },
    {
        id:6,
        img: img6,
        location:"Beach view",
        heading: "Thailand grand suit",
        reviewRating:"4.8/5 Excellent",
        reviewCount:"(1214 reviewes)",
        price:"£99.00",
        discount:false,
    },
    {
        id:7,
        img: img7,
        location:"Long island",
        heading: "Zefi resort and spa",
        reviewRating:"4.8/5 Excellent",
        reviewCount:"(1214 reviewes)",
        price:"£89.00",
        discount:false,
    },
    {
        id:8,
        img: img8,
        location:"Philippine",
        heading: "Manila international resort",
        reviewRating:"4.8/5 Excellent",
        reviewCount:"(1214 reviewes)",
        price:"£99.00",
        discount:false,
    },
]