export const FaqsData = [
    {
        'area_heading': "General question",
        'data': [
            {
                'heading': "It is a long established fact that a reader will be distracted by the readable content?",
                'para': `There are many variations of passages of Lorem Ipsum available,
                  but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.`

            },
            {
                'heading': "It is a long established fact that a reader will be distracted by the readable content?",
                'para': `There are many variations of passages of Lorem Ipsum available,
                  but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.`

            },
            {
                'heading': "It is a long established fact that a reader will be distracted by the readable content?",
                'para': `There are many variations of passages of Lorem Ipsum available,
                  but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.`

            },
            {
                'heading': "It is a long established fact that a reader will be distracted by the readable content?",
                'para': `There are many variations of passages of Lorem Ipsum available,
                  but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.`

            }
        ]
    },
    {
        'area_heading': "Regular question",
        'data': [
            {
                'heading': "It is a long established fact that a reader will be distracted by the readable content?",
                'para': `There are many variations of passages of Lorem Ipsum available,
                  but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.`

            },
            {
                'heading': "It is a long established fact that a reader will be distracted by the readable content?",
                'para': `There are many variations of passages of Lorem Ipsum available,
                  but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.`

            },
            {
                'heading': "It is a long established fact that a reader will be distracted by the readable content?",
                'para': `There are many variations of passages of Lorem Ipsum available,
                  but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.`

            },
            {
                'heading': "It is a long established fact that a reader will be distracted by the readable content?",
                'para': `There are many variations of passages of Lorem Ipsum available,
                  but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.`

            }
        ]
    },
    {
        'area_heading': "Advance question",
        'data': [
            {
                'heading': "It is a long established fact that a reader will be distracted by the readable content?",
                'para': `There are many variations of passages of Lorem Ipsum available,
                  but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.`

            },
            {
                'heading': "It is a long established fact that a reader will be distracted by the readable content?",
                'para': `There are many variations of passages of Lorem Ipsum available,
                  but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.`

            },
            {
                'heading': "It is a long established fact that a reader will be distracted by the readable content?",
                'para': `There are many variations of passages of Lorem Ipsum available,
                  but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.`

            },
            {
                'heading': "It is a long established fact that a reader will be distracted by the readable content?",
                'para': `There are many variations of passages of Lorem Ipsum available,
                  but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.`

            }
        ]
    },
]