
import img1 from '../../../assets/img/imagination/home1.svg'
import img2 from '../../../assets/img/imagination/home2.svg'
import img3 from '../../../assets/img/imagination/home4.svg'



export const DiscoverData = [
    {
        img: img1,
        heading: "7% Discount for all",
        span:"Airlines",
    },
    {
        img: img2,
        heading: "Travel around",
        span:"the world",
    },
    {
        img: img3,
        heading: "Luxury resorts",
        span:"top deals",
    },
]