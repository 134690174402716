import axios from 'axios';

const createAPI = () => {
  const baseURL = process.env.REACT_APP_API_URL || "http://localhost:5000/api/travelark/";

  return axios.create({
    baseURL: baseURL,
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

const api = createAPI();

export default api;
