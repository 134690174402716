import React from 'react'
import ReactGA from "react-ga4"
// Import Link
import { Link } from 'react-router-dom'
// import Img
import Img from '../../../assets/img/home/home-hoilday.jpg'

ReactGA .initialize("G-Z3H4EED561");

const AboutArea = () => {
  return (
    <>
     <section id="about_us_top" className="section_padding">
        <div className="container">
            <div className="row">
                <div className="col-lg-8">
                    <div className="about_us_left">
                        <h5>About us</h5>
                        <h2>We Are The World Best Travel Agency Company</h2>
                        <p>Travel Ark Limited, established in the vibrant heart of the United Kingdom in the second quarter of 2024, emerges as a pioneering budget-friendly travel agency dedicated to enriching your travel experiences without compromising on quality. Born out of a strategic decision to meet the surging demand for travel services post-pandemic, our foundation is built on extensive market research, robust partnerships, and a customer-centric service framework. As a limited company registered with Companies House, we not only ensure financial security and credibility but also pave the way for future growth and flexibility by considering a transition to a partnership structure. Our unique proposition lies in offering bespoke travel packages, leveraging advanced technology for cost-effective solutions, and providing round-the-clock expert customer support. With a focus on personalized service, operational excellence, and innovative marketing strategies, Travel Ark Limited is set to redefine budget travel, making quality experiences accessible to all. Embark on your next journey with us and discover the world in ways you never imagined possible, where value, quality, and care go hand in hand. </p>
                        <Link to="#!" className="btn btn_theme btn_md">Find tours</Link>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="about_us_right">
                        <img src={Img} alt="img" />
                    </div>
                </div>

            </div>
        </div>
    </section>

    </>
  )
}

export default AboutArea