import React from "react";

const Consultation = () => {
  return (
    <>
      <section id="consultation_area" className="section_padding_bottom">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <div className="consultation_area_text">
                <h2>Have you any question? Get A Consultation</h2>
                <p>
                Seek expert advice for your next adventure. Our experienced consultants offer personalized travel recommendations tailored to your preferences and budget. From itinerary planning to destination suggestions, we're here to make your travel dreams a reality. Schedule a consultation today and embark on the journey of a lifetime!
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Consultation;
