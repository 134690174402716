import img1 from '../../../assets/img/icon/world.png'
import img2 from '../../../assets/img/icon/walte.png'
import img3 from '../../../assets/img/icon/star.png'
import img4 from '../../../assets/img/icon/persentis.png'





export const ServiceData = [
    {
        img: img1,
        heading: "Best services",
        para:`Premier travel services: seamless bookings, personalized itineraries, unforgettable journeys. Your trusted adventure partner!"`
    },
   
    {
        img: img2,
        heading: "Trusted payment",
        para:`Secure transactions with trusted payment methods. Your peace of mind is our priority. Experience worry-free payments with us today!`
    },
   
    {
        img: img3,
        heading: "Top facility",
        para:`Top-notch facility: comfort, convenience, and quality service. Elevate your experience with our exceptional amenities.`
    },
   
    {
        img: img4,
        heading: "Awesome deals",
        para:`PDiscover awesome deals: unbeatable prices, exclusive offers. Your gateway to savings and unforgettable experiences!`
    },
   
]