import img1 from '../../../assets/img/review/review1.png'
import img2 from '../../../assets/img/review/review2.png'
import img3 from '../../../assets/img/review/review3.png'





export const ReviewData = [
    {
        img: img1,
        date:"26 Oct 2021",
        status:"Excellent",
        name:"Jesica simpsn", 
        des:"Tourist",
        review:`" Loved the overall tour for all 6 days covering jaipur jodhpur
        and jaisalmer. worth ur money for sure. thanks. Driver was very
        good and polite and safe driving for all 6 days. on time pickup
        and drop overall. Thanks for it. "`
    },
    {
        img: img2,
        date:"08 Dec 2021",
        status:"Excellent",
        name:"Santa mariam", 
        des:"Traveler",
        review:`" Loved the overall tour for all 6 days covering jaipur jodhpur
        and jaisalmer. worth ur money for sure. thanks. Driver was very
        good and polite and safe driving for all 6 days. on time pickup
        and drop overall. Thanks for it. "`
    },
    {
        img: img3,
        date:"26 Oct 2021",
        status:"Excellent",
        name:"Jack cremer", 
        des:"Manager",
        review:`" Loved the overall tour for all 6 days covering jaipur jodhpur
        and jaisalmer. worth ur money for sure. thanks. Driver was very
        good and polite and safe driving for all 6 days. on time pickup
        and drop overall. Thanks for it. "`
    },
    {
        img: img1,
        date:"26 Oct 2021",
        status:"Excellent",
        name:"Jesica simpsn", 
        des:"Tourist",
        review:`" Loved the overall tour for all 6 days covering jaipur jodhpur
        and jaisalmer. worth ur money for sure. thanks. Driver was very
        good and polite and safe driving for all 6 days. on time pickup
        and drop overall. Thanks for it. "`
    },
    {
        img: img2,
        date:"08 Dec 2021",
        status:"Excellent",
        name:"Santa mariam", 
        des:"Traveler",
        review:`" Loved the overall tour for all 6 days covering jaipur jodhpur
        and jaisalmer. worth ur money for sure. thanks. Driver was very
        good and polite and safe driving for all 6 days. on time pickup
        and drop overall. Thanks for it. "`
    },
    {
        img: img3,
        date:"26 Oct 2021",
        status:"Excellent",
        name:"Jack cremer", 
        des:"Manager",
        review:`" Loved the overall tour for all 6 days covering jaipur jodhpur
        and jaisalmer. worth ur money for sure. thanks. Driver was very
        good and polite and safe driving for all 6 days. on time pickup
        and drop overall. Thanks for it. "`
    },
]