import React from 'react'
// import FlightForm 
import FlightForm from './FlightForm'
// import FlightSearchWrapper 
import FlightSearchWrapper from './FlightSearchArea'

const FlightSearchArea = () => {
  return (
    <>
     <FlightForm/>
     <FlightSearchWrapper/>
    </>
  )
}

export default FlightSearchArea