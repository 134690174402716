import React from 'react'
// import Img
import Img from "../../assets/img/common/about1.png"
// Import Icon
import Icon from "../../assets/img/icon/exp-sm-1.png"
import Icon2 from "../../assets/img/icon/exp-sm-2.png"
import Icon3 from "../../assets/img/icon/exp-sm-3.png"


const VideoAreaTwo = () => {
    const data = [
        {
            img:Icon,
            heading:"Mission",
            para:"Our mission is rooted in the belief that travel should be an accessible joy, not a luxury reserved for the privileged few. We are committed to breaking down financial barriers, offering budget-friendly options without compromising quality. With a focus on affordability, reliability, and inclusivity, we aim to empower every client to embark on their dream journeys with confidence. "
        },
        {
            img:Icon2,
            heading:"Vision",
            para:"Our vision is to be the leading provider of sustainable and immersive travel experiences, recognized for our commitment to responsible tourism, unparalleled customer service, and unparalleled passion for exploration. We envision a world where travel is a force for positive change, connecting people across borders and fostering a deep appreciation for the beauty and diversity of our planet."
        },
        // {
        //     img:Icon3,
        //     heading:"24/7 Customer support",
        //     para:"Fugiat dolor enim est proident ea id veniam nulla ipsum cillum aliqua."
        // },
    ]
  return (
    <>
     <section id="video_area_two">
        <div className="container">
            <div className="row">
                <div className="col-lg-6">
                    <div className="video_two_left_side">
                        <h2>Why Travel Ark?</h2>
                        <p>Learn about our core beliefs and future goals as a travel agency committed to making exploration accessible to all.</p>
                        <div className="video_exp_wrapper">
                        {data.map((data1, index) =>(
                             <div className="video_exp_item" key={index}>
                             <div className="video_exp_icon">
                                 <img src={data1.img} alt="icon" />
                             </div>
                             <div className="video_exp_text">
                                 <h3>{data1.heading}</h3>
                                 <p>{data1.para}</p>
                             </div>
                         </div>
                        ))}
                           
                          
                        </div>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="video_two_right_side">
                        <img src={Img} alt="img"/>
                        <div className="video_two_play_area">
                            <a className="video_btn video_play_area"  href="#!"
                                title="Youtube Video"><i className="fas fa-play"></i></a>
                        </div>
                    </div>
                   
                </div>
            </div>
        </div>
    </section>
    </>
  )
}

export default VideoAreaTwo