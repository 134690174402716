import React, { useEffect, useRef } from 'react'

export default function JivoSite() {
    const containerRef = useRef(null);

  useEffect(() => {
    // Create script element
    const script = document.createElement('script');
    script.src = "//code.jivosite.com/widget/yIc8W75GVN";
    script.async = true;

    // Append script element to the container
    if (containerRef.current) {
      containerRef.current.appendChild(script);
    }

    // Cleanup function to remove the script when the component unmounts
    return () => {
      if (script.parentNode) {
        script.parentNode.removeChild(script);
      }
    };
  }, []); // Empty dependency array ensures the effect runs only once on mount

  return (
    <div ref={containerRef}>
      {/* Your JSX content here (if any) */}
    </div>
  )
}
