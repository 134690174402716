
import React, { useState } from 'react';
import axios from 'axios';
// Import link
import { Link } from 'react-router-dom'
// Import Icon
import Icon from '../../assets/img/icon/google.png'
import Icon1 from '../../assets/img/icon/facebook.png'
import Icon2 from '../../assets/img/icon/twitter.png'



const RegisterArea = () => {
    const [formData, setFormData] = useState({
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      address: '',
      password: ''
    });
  
    const handleChange = (e) => {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value
      });
    };
  
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
          const response = await axios.post('http://localhost:5000/api/travelark/register', formData, {
            headers: {
              'Content-Type': 'application/json'
            }
          });
          if (response && response.data) {
            console.log('Registration successful:', response.data);
            // Display success message to user
          } else {
            console.error('Empty response received');
            // Handle empty response
          }
        } catch (error) {
          console.error('Registration failed:', error.message);
          // Display error message to user
        }
    };
      
  return (
    <section id="common_author_area" className="section_padding">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            <div className="common_author_boxed">
              <div className="common_author_heading">
                <h3>Register account</h3>
                <h2>Register your account</h2>
              </div>
              <div className="common_author_form">
                <form onSubmit={handleSubmit}>
                  <div className="form-group">
                    <input type="text" name="first_name" className="form-control" placeholder="Enter first name*" required onChange={handleChange} />
                  </div>
                  <div className="form-group">
                    <input type="text" name="last_name" className="form-control" placeholder="Enter last name*" required onChange={handleChange} />
                  </div>
                  <div className="form-group">
                    <input type="email" name="email" className="form-control" placeholder="Your email address*" required onChange={handleChange} />
                  </div>
                  <div className="form-group">
                    <input type="text" name="phone" className="form-control" placeholder="Mobile number*" required onChange={handleChange} />
                  </div>
                  <div className="form-group">
                    <input type="password" name="password" className="form-control" placeholder="Password*" required onChange={handleChange} />
                  </div>
                  <div className="form-group">
                    <input type="text" name="address" className="form-control" placeholder="Full Address*" required onChange={handleChange} />
                  </div>
                  <div className="common_form_submit">
                    <button type="submit" className="btn btn_theme btn_md">Register</button>
                  </div>
                  <div className="have_acount_area other_author_option">
                    <div className="line_or">
                      <span>or</span>
                    </div>
                    <ul>
                      <li><a href="#!"><img src={Icon} alt="icon" /></a></li>
                      <li><a href="#!"><img src={Icon1} alt="icon" /></a></li>
                      <li><a href="#!"><img src={Icon2} alt="icon" /></a></li>
                    </ul>
                    <p>Already have an account? <Link to="/login">Log in now</Link></p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default RegisterArea;
